/* eslint-disable no-extend-native */
import { useRef, useState, useEffect, useCallback, Fragment} from "react"
import axios from "axios"
import { useNavigate, Link } from "react-router-dom"
import { CSVLink } from "react-csv"
import ReactPaginate from "react-paginate"
import { EyeIcon, CheckIcon, XMarkIcon, ArrowDownTrayIcon } from "@heroicons/react/24/solid"
import { RingLoader } from "react-spinners"
import { Dialog, Transition } from "@headlessui/react"
import axiosInstance from "../../utils/AxiosInstance"
import { BASE_URL, GHL_URL, CALL_SERVICE_URL, Client_ID, Client_Secret } from "../../utils/Config"
import { Tooltip } from "../../components/Tooltip"
import { ProgressIndicator } from '../ProgressIndicator'

function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

const override = {
  borderColor: "#2317e6",
  zIndex: 31,
}

const headers = [
  { label: "Date Created", key: "created_at" },
  { label: "Transcription Text", key: "transcription_text" },
  { label: "User", key: "user" },
  { label: "File Name", key: "file_url" },
  { label: "First Name", key: "first_name" },
  { label: "Last Name", key: "last_name" },
  { label: "Account Name", key: "account_name" },
  { label: "Phone Number", key: "phone_number" },
  { label: "Email", key: "email" },
  { label: "Industry", key: "industry" },
  { label: "Sub-Industry", key: "sub_industry" },
  { label: "Location ID", key: "location_id" },
  { label: "Contact ID", key: "contact_id" },
  { label: "Source", key: "source" },
  { label: "Targeting", key: "targeting" },
  { label: "Content", key: "content" },
  { label: "Last Activity Date", key: "last_activity_date" },
  { label: "Rating", key: "rating" },
  { label: "Summary", key: "summary" },
]

const dateTimeOptions = {
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
  hour: "2-digit",
  minute: "2-digit",
  second: "2-digit",
  hour12: false,
}


export const Recordings = () => {
  const [progress, setProgress] = useState(0)
  const [loading, setLoading] = useState(false)
  const [shouldFetchProgress] = useState(false)

  const isFirstIteration = useRef(true);

  const fetchProgress = useCallback(() => {
    axios.get(`${BASE_URL}/transcription/progress`)
      .then((response) => {
        setProgress(response.data.progress);
        if (isFirstIteration.current || response.data.progress < 100) {
          isFirstIteration.current = false;
          setTimeout(fetchProgress, 500);
        } else {
          console.log(JSON.stringify(response.data));
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);
  

  useEffect(() => {
    if (loading) {
      fetchProgress();
    }
  }, [loading, fetchProgress]);
  

  useEffect(() => {
    if (shouldFetchProgress) {
      setLoading(true);
      fetchProgress();
    }
  }, [shouldFetchProgress, fetchProgress]);
  

  const fileInputRef = useRef(null)
  const handleFileChange = (e) => {
    const result = e.target.files[0]
    if (result) {
      setSelectedFile(result)
    }
  }
  const navigate = useNavigate()
  const userId = Number(localStorage.getItem("id"))
  const itemsPerPage = 10

  const [tabs, setTabs] = useState([])
  const [currentTab, setCurrentTab] = useState("File upload")
  const [selectedFile, setSelectedFile] = useState({})
  const [allRecordingData, setAllRecordingData] = useState([])
  const [filteredData, setFilteredData] = useState([])
  const [searchValue, setSearchValue] = useState("")
  const [csvData, setCsvData] = useState([])
  const [openUploadSuccessModal, setOpenUploadSuccessModal] = useState(false)
  const [openUploadErrorModal, setOpenUploadErrorModal] = useState(false)
  const [numSpeakers, setNumSpeakers] = useState(2)
  const [fileUrl, setFileUrl] = useState("")
  const [openFileUploadModal, setOpenFileUploadModal] = useState(false)
  const [phoneNumber, setPhoneNumber] = useState(null) // full phone number with country code and phone number
  const [phoneCode, setPhoneCode] = useState("+1") // phone country code
  const [phoneValue, setPhoneValue] = useState("") // real phone number
  const [locations, setLocations] = useState([])
  const [selectedLocation, setSelectedLocation] = useState(null)
  // to paginate
  const [itemOffset, setItemOffset] = useState(0)
  const endOffset = itemOffset + itemsPerPage
  const [currentItems, setCurrentItems] = useState([])
  const [pageCount, setPageCount] = useState(0)
  // end paginate

  async function getRecording(accessToken, locationId, messageId) {
    const response = await fetch(`${CALL_SERVICE_URL}/get_call_by_messageid/`, {
      method: "POST",
      headers: {
        'accept': 'application/json',
        'Content-Type': `application/x-www-form-urlencoded`,
      },
      body: new URLSearchParams({
        'authorization_token': accessToken,
        'message_id': messageId,
        'location_id': locationId
      })
    });
    return await response.blob();
  }

  const getAccessToken = async (refreshToken) => {
    const encodedParams = new URLSearchParams();
    encodedParams.set('client_id', Client_ID);
    encodedParams.set('client_secret', Client_Secret);
    encodedParams.set('grant_type', 'refresh_token');
    encodedParams.set('refresh_token', refreshToken);

    const options = {
      method: 'POST',
      url: `${GHL_URL}/oauth/token`,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Accept: 'application/json'
      },
      data: encodedParams,
    };

    const { data } = await axiosInstance.request(options);
    return data;
  }

  async function downloadRecord(filename, refreshToken, locationId, messageId) {
    const { access_token } = await getAccessToken(refreshToken);
    const blob = await getRecording(access_token, locationId, messageId);
    downloadBlob(blob, filename);
  }

  
  function downloadBlob(blob, filename) {
    const link = document.createElement('a');
    link.download = filename;
    link.href = URL.createObjectURL(blob);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(link.href);
  }

  useEffect(() => {
    axiosInstance({
      method: "get",
      url: `${BASE_URL}/analysis/transcriptions`,
      params: {
        userId: userId,
      },
    })
      .then((res) => {
        setAllRecordingData(res.data)
      })
      .catch((err) => {
        console.log(err)
      })

    axiosInstance({
      method: "get",
      url: `${BASE_URL}/locations/active-locations`,
      params: {
        userId: userId,
      },
    })
      .then((res) => {
        res.data && setLocations(res.data.locations)
      })
      .catch((err) => {
        console.log(err)
      })

    setTabs([
      { name: "File upload", current: true },
      { name: "File URL", current: false },
    ])
  }, [userId])

  useEffect(() => {
    if (allRecordingData) {
      setCsvData(allRecordingData)
      setFilteredData(
        allRecordingData.filter(
          (item) =>
            item.createdAt?.toLowerCase().includes(searchValue.toLowerCase()) ||
            item.file.originalname
              ?.toLowerCase()
              .includes(searchValue.toLowerCase()) ||
            item.transcription
              ?.toLowerCase()
              .includes(searchValue.toLowerCase())
        )
      )
    }
  }, [searchValue, allRecordingData])

  useEffect(() => {
    setCurrentItems(filteredData.slice(itemOffset, endOffset))
    setPageCount(Math.ceil(filteredData.length / itemsPerPage))
  }, [filteredData, itemOffset, endOffset])

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % allRecordingData.length
    setItemOffset(newOffset)
  }

  useEffect(() => {
    if (phoneValue === "") {
      setPhoneNumber(null)
    } else {
      setPhoneNumber(phoneCode + phoneValue)
    }
  }, [phoneCode, phoneValue])

  String.prototype.toHHMMSS = function () {
    var sec_num = parseInt(this, 10);
    var hours   = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    var seconds = sec_num - (hours * 3600) - (minutes * 60);

    if (hours   < 10) {hours   = "0"+hours;}
    if (minutes < 10) {minutes = "0"+minutes;}
    if (seconds < 10) {seconds = "0"+seconds;}
    return hours+':'+minutes+':'+seconds;
  }

  useEffect(() => {
    console.log(currentItems);
  }, [currentItems])

  return (
    <div>
      <div className="grid sm:grid-cols-3 grid-cols-1 gap-x-4">
        <input
          type="text"
          name="search"
          id="search"
          className="col-span-1 mx-4 mb-4 block rounded-md bg-white/5 border-0 py-1.5 text-gray-900 dark:text-gray-100 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 w-auto"
          placeholder="Search"
          onChange={(e) => {
            setSearchValue(e.target.value)
          }}
        />
        <div className="sm:flex sm:items-center mx-4 mb-4 sm:col-span-2 col-span-1">
          <Tooltip
            message="Click here to import a recording, you can upload a file or paste a twilio url"
            className="w-max -bottom-9"
          >
            <button
              type="button"
              className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:w-auto w-full sm:mt-0 mt-2"
              onClick={() => {
                setOpenFileUploadModal(true)
              }}
            >
              Import Recording
            </button>
          </Tooltip>
        </div>
      </div>
      <div className="flow-root mb-4">
        <div className="overflow-x-auto">
          <div className="inline-block min-w-full py-2 align-middle px-4">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300 dark:divide-gray-600">
                <thead className="bg-gray-50 dark:bg-gray-700">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 dark:text-gray-100 sm:pl-6"
                    >
                      Date created
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-100"
                    >
                      Date Added
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-100"
                    >
                      Contact ID
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-100"
                    >
                      Speakers
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-100"
                    >
                      Duration
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-100"
                    >
                      First Name
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-100"
                    >
                      Last Name
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-100"
                    >
                      Phone number
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-100"
                    >
                      Location
                    </th>
                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                    >
                      <span className="sr-only">View</span>
                    </th>
                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                    >
                      <span className="sr-only">Download</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 dark:divide-gray-700 bg-white dark:bg-gray-800">
                  {currentItems &&
                    currentItems.map((datum, index) => (
                      <tr key={index}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-500 dark:text-gray-300 sm:pl-6">
                          {datum.createdAt &&
                            new Date(datum.createdAt).toLocaleDateString(
                              "en-US",
                              dateTimeOptions
                            )}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 dark:text-gray-300">
                          {datum.dateAdded ? new Date(datum.dateAdded).toLocaleDateString("en-US", dateTimeOptions) : '-'}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 dark:text-gray-300">
                          {datum.file && datum.crm_userId}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 dark:text-gray-300">
                          {datum.num_speakers && datum.num_speakers}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 dark:text-gray-300">
                          {datum.duration && datum.duration.toString().toHHMMSS()}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 dark:text-gray-300">
                          {datum.crm_firstName && datum.crm_firstName}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 dark:text-gray-300">
                          {datum.crm_lastName && datum.crm_lastName}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 dark:text-gray-300">
                          {datum.num_speakers && datum.phone_number}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 dark:text-gray-300">
                          {datum.location && datum.location.name}
                        </td>
                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                          <EyeIcon
                            className="w-5 h-5 cursor-pointer dark:text-gray-300"
                            onClick={() => {
                              navigate(`/main/call-analysis/${datum.id}`)
                            }}
                          />
                        </td>
                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                          <ArrowDownTrayIcon
                            className="w-5 h-5 cursor-pointer dark:text-gray-300"
                            onClick={() => {
                              downloadRecord(datum.file.originalname, datum.location.refresh_token, datum.location.location_id, datum.file.message_id)
                            }}
                          />
                          
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center mt-3">
        <ReactPaginate
          nextLabel=">"
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          marginPagesDisplayed={3}
          pageCount={pageCount}
          previousLabel="<"
          pageClassName="relative z-10 inline-flex items-center text-sm font-semibold focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600 border dark:text-gray-300"
          pageLinkClassName="px-4 py-2"
          previousClassName="relative inline-flex items-center rounded-l-md text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
          previousLinkClassName="px-2 py-2"
          nextClassName="relative inline-flex items-center rounded-r-md text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
          nextLinkClassName="px-2 py-2"
          breakLabel="..."
          breakClassName="relative inline-flex items-center text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
          breakLinkClassName="px-4 py-2"
          containerClassName="isolate inline-flex -space-x-px rounded-md shadow-sm"
          activeClassName="bg-indigo-600 text-gray-900 dark:text-white"
          disabledClassName=""
          renderOnZeroPageCount={null}
        />
      </div>
      <CSVLink
        data={csvData}
        headers={headers}
        filename="nabl_recordings.csv"
        className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 ml-4"
      >
        Export CSV
      </CSVLink>
      {/* Spinner */}
      {loading && (
        <div className="z-30 fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity opacity-100 flex items-center justify-center">
          <RingLoader
            color="#2317e6"
            loading={loading}
            cssOverride={override}
            size={50}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
          <ProgressIndicator progress={progress} />
        </div>
      )}
      {/* End Spinner */}
      {/* upload success modal */}
      <Transition.Root show={openUploadSuccessModal} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={setOpenUploadSuccessModal}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white dark:bg-gray-800 px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                  <div>
                    <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-900">
                      <CheckIcon
                        className="h-6 w-6 text-green-300"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900 dark:text-gray-100"
                      >
                        Upload successful
                      </Dialog.Title>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      onClick={() => {
                        setSelectedFile({})
                        setOpenUploadSuccessModal(false)
                      }}
                    >
                      OK
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      {/* end modal */}
      

      <Transition.Root show={openUploadErrorModal} as={Fragment}>
  <Dialog as="div" className="relative z-10" onClose={setOpenUploadErrorModal}>
    <Transition.Child
      as={Fragment}
      enter="ease-out duration-300"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="ease-in duration-200"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
    </Transition.Child>

    <div className="fixed inset-0 z-10 overflow-y-auto">
      <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enterTo="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 translate-y-0 sm:scale-100"
          leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white dark:bg-gray-800 px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
            <div>
              <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-red-100">
                <XMarkIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
              </div>
              <div className="mt-3 text-center sm:mt-5">
                <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900 dark:text-gray-100">
                  Upload Failed
                </Dialog.Title>
                <div className="mt-2">
                  <p className="text-sm text-gray-500">
                    There was an error processing your upload. Please try again.
                  </p>
                </div>
              </div>
            </div>
            <div className="mt-5 sm:mt-6">
              <button
                type="button"
                className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                onClick={() => setOpenUploadErrorModal(false)}
              >
                OK
              </button>
            </div>
          </Dialog.Panel>
        </Transition.Child>
      </div>
    </div>
  </Dialog>
</Transition.Root>


      {/* import file modal */}
      <Transition.Root show={openFileUploadModal} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={setOpenFileUploadModal}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white dark:bg-gray-800 px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 w-full sm:max-w-md sm:p-6">
                  <div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900 dark:text-gray-100"
                      >
                        Import file
                      </Dialog.Title>
                      <div className="mt-2">
                        <div className="border-b border-gray-200 dark:border-gray-700">
                          <nav
                            className="-mb-px flex space-x-8"
                            aria-label="Tabs"
                          >
                            {tabs.length > 0 &&
                              tabs.map((tab) => (
                                <Link
                                  key={tab.name}
                                  className={classNames(
                                    tab.current
                                      ? "border-indigo-500 text-indigo-600"
                                      : "border-transparent text-gray-500 dark:text-gray-300 hover:border-gray-300 dark:hover:border-indigo-400 hover:text-gray-700",
                                    "whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium"
                                  )}
                                  aria-current={
                                    tab.current ? "page" : undefined
                                  }
                                  onClick={(e) => {
                                    e.preventDefault()
                                    setTabs((_prev) => {
                                      const temp = [..._prev]
                                      for (let i = 0; i < temp.length; i++) {
                                        if (temp[i].name === tab.name) {
                                          temp[i].current = true
                                        } else {
                                          temp[i].current = false
                                        }
                                      }
                                      return temp
                                    })
                                    setCurrentTab(tab.name)
                                  }}
                                >
                                  {tab.name}
                                </Link>
                              ))}
                          </nav>
                        </div>
                      </div>
                      {currentTab === "File upload" && (
                        <div className="mt-4">
                          <div className="flex items-center">
                            <button
                              className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                              onClick={() => {
                                // if ((phoneNumber && !selectedLocation) || (!phoneNumber && selectedLocation)) {
                                //   alert('Please provide both a phone number and a location.')
                                //   return
                                // }
                                fileInputRef.current.click()
                              }}
                            >
                              Upload file
                            </button>
                            <p className="sm:ml-2 sm:mt-0 mt-2 text-sm dark:text-gray-300">
                              <span className="font-medium dark:text-gray-100">
                                Selected file:{" "}
                              </span>
                              {selectedFile.name ? selectedFile.name : "None"}
                            </p>
                            <input
                              type="file"
                              className="hidden"
                              accept="audio/*"
                              ref={fileInputRef}
                              onChange={handleFileChange}
                            />
                          </div>
                          <div className="mt-2">
                            <label
                              htmlFor="speakers"
                              className="block text-sm leading-6 text-gray-900 dark:text-gray-100 text-left"
                            >
                              Number of speakers (optional)
                            </label>
                            <div className="mt-2">
                              <input
                                type="number"
                                id="speakers"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                defaultValue={2}
                                onChange={(e) => setNumSpeakers(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="mt-2">
                            <label
                              htmlFor="phone"
                              className="block text-sm leading-6 text-gray-900 dark:text-gray-100 text-left"
                            >
                              Phone number (optional)
                            </label>
                            <div className="relative mt-2 rounded-md shadow-sm">
                              <div className="absolute inset-y-0 left-0 flex items-center">
                                <label htmlFor="country" className="sr-only">
                                  Country
                                </label>
                                <select
                                  id="country"
                                  name="country"
                                  autoComplete="country"
                                  className="h-full rounded-md border-0 bg-transparent py-0 pl-3 pr-7 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm"
                                  onChange={(e) =>
                                    setPhoneCode(e.target.value.split(" ")[1])
                                  }
                                >
                                  <option>US +1</option>
                                  <option>Canada +1</option>
                                  <option>UK +44</option>
                                  <option>Mexico +52</option>
                                </select>
                              </div>
                              <input
                                type="text"
                                name="phone-number"
                                id="phone-number"
                                className="block w-full rounded-md border-0 py-1.5 pl-32 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                placeholder="5559876543"
                                onChange={(e) => setPhoneValue(e.target.value)}
                              />
                            </div>
                            {selectedLocation && phoneValue === "" && (
                              <p className="text-orange-600 text-left text-sm leading-6">
                                Phone number field is empty, press{" "}
                                <b>Upload recording</b> to proceed
                              </p>
                            )}
                          </div>
                          <div className="mt-2">
                            <label
                              htmlFor="location"
                              className="block text-sm leading-6 text-gray-900 dark:text-gray-100 text-left"
                            >
                              Account (optional)
                            </label>
                            <select
                              id="location"
                              name="location"
                              className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              defaultValue="None"
                              onChange={(e) => {
                                const result = locations.find(
                                  (location) => location.name === e.target.value
                                )
                                result
                                  ? setSelectedLocation(result.id)
                                  : setSelectedLocation(null)
                              }}
                            >
                              <option>None</option>
                              {locations.length > 0 &&
                                locations.map((location) => (
                                  <option key={location.id}>
                                    {location.name}
                                  </option>
                                ))}
                            </select>
                            <p className="text-center text-sm text-gray-600 dark:text-gray-400 mt-4">
                                  Note: If you provide a phone number, you must also select an account, and vice versa.
                            </p>
                          </div>
                          <div className="mt-5 sm:mt-6">
                            <button
                              disabled={!selectedFile.name || (phoneNumber && !selectedLocation) || (!phoneNumber && selectedLocation)}
                              type="button"
                              className={classNames(
                                selectedFile.name && ((phoneNumber && selectedLocation) || (!phoneNumber && !selectedLocation))
                                  ? "bg-indigo-600 hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                  : "bg-indigo-400 cursor-default",
                                "rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm w-full sm:mt-0 mt-2"
                              )}
                              onClick={() => {
                                if ((phoneNumber && !selectedLocation) || (!phoneNumber && selectedLocation)) {
                                  alert('Upload failed: Please provide both a phone number and an account.')
                                  return
                                }
                                if (phoneNumber && !selectedLocation) {
                                  alert('Upload failed: Please select an account when providing a phone number.')
                                  return
                                }
                                if ((phoneValue && !selectedLocation) || (!phoneValue && selectedLocation)) {
                                  alert('Upload failed: Please provide both a phone number and an account.')
                                  return
                                }
                                const data = {
                                  user_id: userId,
                                  upload_type: "uploaded_file",
                                  num_speakers: numSpeakers,
                                  phone_number: phoneNumber,
                                  location_id: selectedLocation,
                                }
                                const formData = new FormData()
                                formData.append("file", selectedFile)
                                formData.append("json", JSON.stringify(data))

                                setLoading(true)
                                axiosInstance({
                                  method: "post",
                                  url: `${BASE_URL}/analysis/upload_file`,
                                  data: formData,
                                })
                                .then((res) => {
                                    if (res.data.success === "file uploaded successfully") {
                                      axiosInstance({
                                        method: "get",
                                        url: `${BASE_URL}/analysis/transcriptions`,
                                        params: {
                                          userId: userId,
                                        },
                                      })
                                        .then((res) => {
                                          setAllRecordingData(res.data)
                                          setOpenUploadSuccessModal(true)
                                        })
                                        .catch((err) => {
                                          setOpenUploadErrorModal(true)
                                          console.log(err)
                                        })
                                    } else if (res.data.error) {
                                      setOpenUploadErrorModal(true)
                                    }
                                  })
                                  .catch((err) => {
                                    console.log(err)
                                    setOpenUploadErrorModal(true)
                                    setOpenUploadErrorModal(true)
                                  })
                                  .finally(() => setLoading(false))
                                setOpenFileUploadModal(false)
                              }}
                            >
                              Upload recording
                            </button>
                          </div>
                        </div>
                      )}
                      {currentTab === "File URL" && (
                        <div className="mt-4">
                          <div>
                            <label
                              htmlFor="file_url"
                              className="block text-sm leading-6 text-gray-900 dark:text-gray-100 text-left"
                            >
                              File URL
                            </label>
                            <div className="mt-2">
                              <input
                                type="text"
                                id="file_url"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                onChange={(e) => setFileUrl(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="mt-2">
                            <label
                              htmlFor="speakers"
                              className="block text-sm leading-6 text-gray-900 dark:text-gray-100 text-left"
                            >
                              Number of speakers (optional)
                            </label>
                            <div className="mt-2">
                              <input
                                type="number"
                                id="speakers"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                defaultValue={2}
                                onChange={(e) => setNumSpeakers(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="mt-2">
                            <label
                              htmlFor="phone"
                              className="block text-sm leading-6 text-gray-900 dark:text-gray-100 text-left"
                            >
                              Phone number (optional)
                            </label>
                            <div className="relative mt-2 rounded-md shadow-sm">
                              <div className="absolute inset-y-0 left-0 flex items-center">
                                <label htmlFor="country" className="sr-only">
                                  Country
                                </label>
                                <select
                                  id="country"
                                  name="country"
                                  autoComplete="country"
                                  className="h-full rounded-md border-0 bg-transparent py-0 pl-3 pr-7 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm"
                                  onChange={(e) =>
                                    setPhoneCode(e.target.value.split(" ")[1])
                                  }
                                >
                                  <option>US +1</option>
                                  <option>Canada +1</option>
                                  <option>UK +44</option>
                                  <option>Mexico +52</option>
                                </select>
                              </div>
                              <input
                                type="text"
                                name="phone-number"
                                id="phone-number"
                                className="block w-full rounded-md border-0 py-1.5 pl-32 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                placeholder="5559876543"
                                onChange={(e) => setPhoneValue(e.target.value)}
                              />
                            </div>
                            {selectedLocation && (
                              <p className="text-orange-600 text-left text-sm leading-6">
                                You cannot call CRM API if you don't add your
                                phone number
                              </p>
                            )}
                          </div>
                          <div className="mt-2">
                            <label
                              htmlFor="location"
                              className="block text-sm leading-6 text-gray-900 dark:text-gray-100 text-left"
                            >
                              Account (optional)
                            </label>
                            <select
                              id="location"
                              name="location"
                              className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              defaultValue="None"
                              onChange={(e) => {
                                const result = locations.find(
                                  (location) => location.name === e.target.value
                                )
                                result
                                  ? setSelectedLocation(result.id)
                                  : setSelectedLocation(null)
                              }}
                            >
                              <option>None</option>
                              {locations.length > 0 &&
                                locations.map((location) => (
                                  <option key={location.id}>
                                    {location.name}
                                  </option>
                                ))}
                            </select>
                          </div>
                          <div className="mt-5 sm:mt-6">
                            <button
                              disabled={!fileUrl || (phoneNumber && !selectedLocation) || (!phoneNumber && selectedLocation)}
                              type="button"
                              className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                              onClick={() => {
                                // if ((phoneNumber && !selectedLocation) || (!phoneNumber && selectedLocation)) {
                                //   alert('Upload failed: Please provide both a phone number and an account.')
                                //   return
                                // }
                                // if (phoneNumber && !selectedLocation) {
                                //   alert('Upload failed: Please select an account when providing a phone number.')
                                //   return
                                // }
                                // if ((phoneValue && !selectedLocation) || (!phoneValue && selectedLocation)) {
                                //   alert('Upload failed: Please provide both a phone number and an account.')
                                //   return
                                // }
                                setLoading(true)
                                setOpenFileUploadModal(false)
                                axiosInstance({
                                  method: "post",
                                  url: `${BASE_URL}/analysis/file-url`,
                                  data: {
                                    file_url: fileUrl,
                                    num_speakers: numSpeakers,
                                    user_id: userId,
                                    upload_type: "file_url",
                                    phone_number: phoneNumber,
                                    location_id: selectedLocation,
                                  },
                                })
                                  .then((res) => {
                                    if (
                                      res.data.success ===
                                      "file uploaded successfully"
                                    ) {
                                      axiosInstance({
                                        method: "get",
                                        url: `${BASE_URL}/analysis/transcriptions`,
                                        params: {
                                          userId: userId,
                                        },
                                      })
                                        .then((res) => {
                                          setAllRecordingData(res.data)
                                          setCurrentTab("File upload")
                                        })
                                        .catch((err) => {
                                          console.log(err)
                                        })
                                    } else if (res.data.error) {
                                      setOpenUploadErrorModal(true)
                                    }
                                  })
                                  .catch((err) => {
                                    console.log(err)
                                  })
                                  .finally(() => {
                                    setLoading(false)
                                  })
                              }}
                            >
                              Upload recording
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      {/* end import file modal */}
    </div>
  )
}
// const uploadButtonDisabled = useMemo(() => {
//   return (phoneNumber && !selectedLocation) || (!phoneNumber && selectedLocation)
// }, [phoneNumber, selectedLocation])