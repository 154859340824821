import "react-date-range/dist/styles.css";
import "./date-range-input.css";
import { DateRange } from "react-date-range";
import { useState, useEffect } from "react";
import { Popover, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { startOfMonth } from "date-fns";
import {
  CalendarDaysIcon,
  XCircleIcon,
  CheckCircleIcon,
} from "@heroicons/react/24/outline";

/**
 * @param {Date} date Date to format
 * @returns {String} formated date
 */
function formatDate(date) {
  return date.toLocaleString("en-US", {
    month: "short",
    day: "2-digit",
    year: "numeric",
  });
}

export default function DateRangeInput({ onChange, pickerOpts }) {
  const defaultData = {
    startDate: startOfMonth(new Date()),
    endDate: new Date(),
    key: "selection",
  };

  const [state, setState] = useState([defaultData]);
  const [selectedState, setSelectedState] = useState(null);

  useEffect(() => {
    if (onChange) onChange(selectedState);
  }, [selectedState, onChange]);

  return (
    <Popover>
      {({ open, close }) => (
        <>
          <Popover.Button className="flex w-full gap-2 items-center rounded-md border-0 py-1.5 px-4 bg-white dark:bg-gray-800 text-gray-400 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-700 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 min-w-72 flex-1">
            <CalendarDaysIcon width="1.125rem" height="1.125rem" />
            {!selectedState
              ? "Pick a date"
              : `${formatDate(selectedState?.startDate)} - ${formatDate(selectedState?.endDate)}`}
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="mt-3 min-w-fit transform px-4 sm:px-0 max-w-fit rounded-lg overflow-hidden bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-700">
              <DateRange
                editableDateInputs={true}
                onChange={(item) => setState([item.selection])}
                moveRangeOnFirstSelection={false}
                ranges={state}
                {...pickerOpts}
              />
              <div className="bg-gray-100 dark:bg-gray-700 w-full p-4 flex gap-4">
                <button
                  onClick={() => {
                    setSelectedState(null);
                    close();
                  }}
                  className="flex gap-2 justify-center items-center rounded-md bg-gray-400 dark:bg-gray-800 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 flex-1 min-w-fit"
                >
                  <XCircleIcon width="1.25rem" />
                  Reset
                </button>
                <button
                  onClick={() => {
                    setSelectedState(state[0]);
                    close();
                  }}
                  className="flex gap-2 justify-center items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 flex-1 min-w-fit"
                >
                  <CheckCircleIcon width="1.25rem" />
                  Select Range
                </button>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
}
