import React, { useState, useEffect, useCallback, useRef } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Send, User, Bot, ChevronRight, Plus, MenuIcon, MoreVertical, Trash, X } from 'lucide-react';
import { v4 as uuidv4 } from 'uuid';

const API_BASE_URL = 'https://api-agent.yaitec.dev';

const QuickActions = [
  {
    title: "📋 Today's Leads",
    description: "List all the leads from today"
  },
  {
    title: "🏆 Top Leads",
    description: "Who are the top leads today?"
  },
  {
    title: "🆕 New Leads",
    description: "List all leads created today"
  },
  {
    title: "🚀 Nabl AI",
    description: "Who are you and what can you do?"
  }
];

const DeleteConfirmationModal = ({ isOpen, onClose, onConfirm, chatTitle }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
      <div className="bg-white dark:bg-gray-800 rounded-lg max-w-md w-full shadow-xl">
        <div className="p-6">
          <h3 className="text-xl font-semibold text-gray-900 dark:text-gray-100 mb-4">
            Delete chat?
          </h3>
          <p className="text-sm text-gray-600 dark:text-gray-400 mb-6">
            This will delete <span className="font-medium">{chatTitle}</span>.
            <br />
            To delete any memories from this chat, please access your settings.
          </p>
          <div className="flex justify-end gap-3">
            <button
              onClick={onClose}
              className="px-4 py-2 text-sm font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg transition-colors"
            >
              Cancel
            </button>
            <button
              onClick={onConfirm}
              className="px-4 py-2 text-sm font-medium text-white bg-red-500 hover:bg-red-600 rounded-lg transition-colors"
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const TypingIndicator = () => (
  <div className="flex items-end space-x-1 px-4 py-2 rounded-lg bg-gray-200 dark:bg-gray-700 w-16">
    <div className="w-2 h-2 rounded-full bg-gray-500 dark:bg-gray-400 animate-bounce"
      style={{ animationDelay: '0ms', animationDuration: '600ms' }} />
    <div className="w-2 h-2 rounded-full bg-gray-500 dark:bg-gray-400 animate-bounce"
      style={{ animationDelay: '150ms', animationDuration: '600ms' }} />
    <div className="w-2 h-2 rounded-full bg-gray-500 dark:bg-gray-400 animate-bounce"
      style={{ animationDelay: '300ms', animationDuration: '600ms' }} />
  </div>
);

const ChatMessage = ({ message, from }) => (
  <div className={`flex ${from === 'user' ? 'justify-end' : 'justify-start'} mb-4`}>
    <div className={`flex ${from === 'user' ? 'flex-row-reverse' : 'flex-row'} items-end max-w-3xl gap-2`}>
      <div className={`flex-shrink-0 ${from === 'user' ? 'ml-2' : 'mr-2'}`}>
        <div className={`w-8 h-8 rounded-full flex items-center justify-center ${from === 'user'
          ? 'bg-blue-500 text-white'
          : 'bg-gray-200 dark:bg-gray-700 text-gray-700 dark:text-gray-200'
          }`}>
          {from === 'user' ? <User className="w-5 h-5" /> : <Bot className="w-5 h-5" />}
        </div>
      </div>
      <div className={`px-4 py-2 rounded-lg ${from === 'user'
        ? 'bg-blue-500 text-white rounded-br-none'
        : 'bg-gray-200 dark:bg-gray-700 text-gray-900 dark:text-gray-100 rounded-bl-none'
        }`}>
        <ReactMarkdown
          remarkPlugins={[remarkGfm]}
          className="prose dark:prose-invert max-w-none"
        >
          {message}
        </ReactMarkdown>
      </div>
    </div>
  </div>
);

const ChatItem = ({ chat, onDelete, isActive, onSelect }) => {
  const [showMenu, setShowMenu] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setShowMenu(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleDeleteClick = () => {
    setShowMenu(false);
    setShowDeleteModal(true);
  };

  const handleConfirmDelete = () => {
    onDelete(chat.id);
    setShowDeleteModal(false);
  };

  const handleClick = () => {
    onSelect();
  };

  return (
    <>
      <div
        className={`relative flex items-center p-3 rounded-lg mb-2 transition-colors duration-200 cursor-pointer ${isActive
          ? 'bg-blue-100 dark:bg-blue-900'
          : 'bg-gray-100 dark:bg-gray-800 hover:bg-gray-200 dark:hover:bg-gray-700'
          }`}
        onClick={handleClick}
      >
        <div className="flex-1 text-left overflow-hidden">
          <div className="text-sm font-medium text-gray-900 dark:text-gray-100 truncate">
            {chat.title}
          </div>
          <div className="text-xs text-gray-500 dark:text-gray-400 truncate">
            {chat.preview}
          </div>
        </div>
        <div className="relative" ref={menuRef}>
          <button
            onClick={(e) => {
              e.stopPropagation();
              setShowMenu(!showMenu);
            }}
            className="ml-2 p-1 rounded-full hover:bg-gray-300 dark:hover:bg-gray-600 transition-colors"
          >
            <MoreVertical className="w-4 h-4 text-gray-500 dark:text-gray-400" />
          </button>
          {showMenu && (
            <div className="absolute right-0 mt-2 w-48 bg-white dark:bg-gray-700 rounded-lg shadow-lg z-50">
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  handleDeleteClick();
                }}
                className="w-full flex items-center gap-2 p-3 text-red-500 hover:bg-gray-100 dark:hover:bg-gray-600 rounded-lg"
              >
                <Trash className="w-4 h-4" />
                Delete Conversation
              </button>
            </div>
          )}
        </div>
      </div>

      <DeleteConfirmationModal
        isOpen={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onConfirm={handleConfirmDelete}
        chatTitle={chat.title}
      />
    </>
  );
};

const InputArea = ({ isStandalone = false, message, setMessage, handleSendMessage, isLoading, handleKeyDown }) => {
  return (
    <div className={`${isStandalone ? "w-full max-w-2xl bg-white dark:bg-gray-900 p-4 rounded-lg shadow-sm" : "sticky bottom-0 w-full bg-white dark:bg-gray-900 border-t border-gray-200 dark:border-gray-800 p-4"}`}>
      <div className={`${isStandalone ? "" : "max-w-3xl mx-auto"}`}>
        <div className="flex items-center gap-4">
          <textarea
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            onKeyDown={handleKeyDown}
            placeholder="Send a message to NABL"
            rows={2}
            className="flex-grow px-4 py-3 rounded-lg bg-gray-100 dark:bg-gray-800 text-gray-700 dark:text-gray-200 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 border border-gray-300 dark:border-gray-700"
            style={{
              resize: "none",
              minHeight: "44px",
              maxHeight: "200px",
            }}
          />
          <button
            onClick={() => handleSendMessage(message)}
            disabled={isLoading}
            className="p-2 rounded-lg bg-blue-500 hover:bg-blue-600 text-white transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
          >
            <Send className="w-5 h-5" />
          </button>
        </div>
        <p className="text-xs text-center text-gray-500 mt-2">
          AI Assistant is ready to help analyze your calls and provide insights.
        </p>
      </div>
    </div>
  );
};

export const Dashboard = () => {
  const [message, setMessage] = useState('');
  const [chatMessages, setChatMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(true);
  const [currentSessionId, setCurrentSessionId] = useState(null);
  const [conversations, setConversations] = useState({ today: [], yesterday: [], older: [] });
  const [error, setError] = useState(null);
  const messagesEndRef = useRef(null);
  const chatContainerRef = useRef(null);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const fetchMessageHistoryBySession = useCallback(async (sessionId) => {
    if (!sessionId) {
      setError('Invalid session ID');
      return;
    }

    try {
      setIsLoading(true);
      setError(null);

      const url = `${API_BASE_URL}/message-history/${sessionId}`;
      const response = await fetch(url);

      if (!response.ok) {
        throw new Error(`Failed to fetch session history: ${response.statusText}`);
      }

      const data = await response.json();

      if (!data || typeof data !== 'object') {
        throw new Error('Invalid response format');
      }

      let messages = [];
      if (Array.isArray(data.history)) {
        messages = data.history;
      } else if (Array.isArray(data.messages)) {
        messages = data.messages;
      } else {
        throw new Error('Messages data is not in the expected format');
      }

      const formattedMessages = messages.map((msg) => ({
        from: (msg.sender || msg.role || '').toString().toUpperCase() === 'USER' ? 'user' : 'bot',
        message: msg.content || msg.message || '',
      }));

      if (formattedMessages.length > 0) {
        setChatMessages(formattedMessages);
        setCurrentSessionId(sessionId);
      } else {
        setChatMessages([]);
      }
    } catch (error) {
      console.error('Error fetching session history:', error);
      setError('Failed to load chat history. Please try again.');
      setChatMessages([]);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const fetchConversations = useCallback(async () => {
    try {
      const id_usuario = localStorage.getItem('id');
      const response = await fetch(`${API_BASE_URL}/message-history-user-id/${id_usuario}`);
      const data = await response.json();

      const today = new Date();
      today.setHours(0, 0, 0, 0);
      const yesterday = new Date(today);
      yesterday.setDate(yesterday.getDate() - 1);

      const todayChats = [];
      const yesterdayChats = [];
      const olderChats = [];

      if (data.status === "success" && Array.isArray(data.history)) {
        data.history.forEach(chat => {
          const firstMessage = chat.messages[0];
          const lastMessage = chat.messages[chat.messages.length - 1];

          const formattedChat = {
            id: chat.id,
            session_id: chat.id,
            title: firstMessage.message?.slice(0, 30) + "..." || "Sem título",
            preview: lastMessage.message?.slice(0, 40) + "..." || "Sem preview",
            timestamp: firstMessage.timestamp || new Date().toISOString()
          };

          const chatDate = new Date(formattedChat.timestamp);
          chatDate.setHours(0, 0, 0, 0);

          if (chatDate.getTime() === today.getTime()) {
            todayChats.push(formattedChat);
          } else if (chatDate.getTime() === yesterday.getTime()) {
            yesterdayChats.push(formattedChat);
          } else {
            olderChats.push(formattedChat);
          }
        });
      }

      setConversations({
        today: todayChats.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp)),
        yesterday: yesterdayChats.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp)),
        older: olderChats.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
      });
    } catch (error) {
      console.error('Error fetching conversations:', error);
      setError('Failed to load conversations. Please try again.');
    }
  }, []);

  const deleteChat = async (chatId) => {
    try {
      const response = await fetch(`${API_BASE_URL}/message-history/${chatId}`, {
        method: 'DELETE'
      });

      if (!response.ok) throw new Error('Failed to delete chat');

      setConversations(prev => ({
        today: prev.today.filter(chat => chat.id !== chatId),
        yesterday: prev.yesterday.filter(chat => chat.id !== chatId),
        older: prev.older.filter(chat => chat.id !== chatId)
      }));

      if (currentSessionId === chatId) {
        startNewChat();
      }
    } catch (error) {
      console.error('Error deleting chat:', error);
      setError('Failed to delete chat. Please try again.');
    }
  };
  const handleSendMessage = async (messageText) => {
    if (!messageText.trim()) return;

    let sessionIdToUse = currentSessionId;
    if (!sessionIdToUse) {
      sessionIdToUse = uuidv4();
      setCurrentSessionId(sessionIdToUse);
    }

    const newMessage = { from: 'user', message: messageText.trim() };
    setChatMessages(prev => [...prev, newMessage]);
    setMessage('');
    setIsLoading(true);
    setError(null);

    scrollToBottom();

    try {
      const response = await fetch(`${API_BASE_URL}/nabl-agent/query`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          query: messageText,
          session_id: sessionIdToUse,
          id_usuario: localStorage.getItem('id') || ''
        })
      });

      if (!response.ok) {
        throw new Error('Failed to send message');
      }

      const data = await response.json();

      setChatMessages(prev => [...prev, {
        from: 'bot',
        message: data.response
      }]);

      scrollToBottom();

      await fetchConversations();

    } catch (error) {
      console.error('Error sending message:', error);
      setError('Failed to send message. Please try again.');
      setChatMessages(prev => [...prev, {
        from: 'bot',
        message: "Sorry, I couldn't process your request. Please try again."
      }]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [chatMessages, isLoading]);

  const startNewChat = () => {
    setChatMessages([]);
    setMessage('');
    setCurrentSessionId(uuidv4());
    setError(null);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage(message);
    }
  };

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [chatMessages, isLoading]);

  useEffect(() => {
    fetchConversations();
  }, [fetchConversations]);

  return (
    <div className="flex h-screen bg-gray-50 dark:bg-gray-900">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="lg:hidden fixed top-4 left-4 p-2 rounded-lg bg-gray-200 dark:bg-gray-700 z-50"
      >
        {isOpen ? <X className="w-5 h-5" /> : <MenuIcon className="w-5 h-5" />}
      </button>

      <div
        className={`${isOpen ? "translate-x-0" : "-translate-x-full"} 
          fixed lg:relative lg:translate-x-0 
          w-64 h-screen bg-gray-100 dark:bg-[#0f1729] border-r border-gray-200 dark:border-gray-800
          transition-transform duration-300 ease-in-out flex flex-col z-40`}
      >
        <div className="p-3 border-b border-gray-200 dark:border-gray-800">
          <button
            onClick={startNewChat}
            className="w-full flex items-center justify-center gap-2 p-2.5 rounded-lg
              bg-blue-500 text-white hover:bg-blue-600 transition-colors"
          >
            <Plus className="w-4 h-4" />
            <span>New Chat</span>
          </button>
        </div>

        <div className="flex-1 overflow-y-auto px-2">
          {conversations.today.length > 0 && (
            <div className="space-y-1 mb-4">
              <div className="text-xs font-medium text-gray-500 dark:text-gray-400 px-3 py-2">Today</div>
              {conversations.today.map((chat) => (
                <ChatItem
                  key={chat.id}
                  chat={chat}
                  onSelect={() => fetchMessageHistoryBySession(chat.id)}
                  onDelete={deleteChat}
                  isActive={chat.id === currentSessionId}
                />
              ))}
            </div>
          )}

          {conversations.yesterday.length > 0 && (
            <div className="space-y-1">
              <div className="text-xs font-medium text-gray-500 dark:text-gray-400 px-3 py-2">Yesterday</div>
              {conversations.yesterday.map((chat) => (
                <ChatItem
                  key={chat.id}
                  chat={chat}
                  onSelect={() => fetchMessageHistoryBySession(chat.id)}
                  onDelete={deleteChat}
                  isActive={chat.id === currentSessionId}
                />
              ))}
            </div>
          )}

          {conversations.older.length > 0 && (
            <div className="space-y-1">
              <div className="text-xs font-medium text-gray-500 dark:text-gray-400 px-3 py-2">Older</div>
              {conversations.older.map((chat) => (
                <ChatItem
                  key={chat.id}
                  chat={chat}
                  onSelect={() => fetchMessageHistoryBySession(chat.id)}
                  onDelete={deleteChat}
                  isActive={chat.id === currentSessionId}
                />
              ))}
            </div>
          )}

          {conversations.today.length === 0 && conversations.yesterday.length === 0 && (
            <div className="text-sm text-center text-gray-500 dark:text-gray-400 mt-4">No conversations yet</div>
          )}
        </div>
      </div>

      <div className="flex-1 flex flex-col">
        <div className="flex-1 p-4 overflow-y-auto">
          {chatMessages.length === 0 ? (
            <div className="flex flex-col items-center max-w-3xl mx-auto">
              <div className="w-16 h-16 bg-gray-100 dark:bg-gray-800 rounded-2xl flex items-center justify-center mb-4">
                <span className="text-xl text-gray-700 dark:text-gray-200">NABL</span>
              </div>

              <h2 className="text-2xl font-semibold text-gray-900 dark:text-white mb-2 text-center">
                Call Analysis AI
              </h2>
              <p className="text-gray-600 dark:text-gray-400 text-center max-w-md mb-6">
                Analyze and understand your calls with the power of AI. Get insights, transcriptions, and
                recommendations to improve your communication.
              </p>

              <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 w-full max-w-2xl mb-6">
                {QuickActions.map((action, index) => (
                  <button
                    key={index}
                    onClick={() => handleSendMessage(action.description)}
                    className="p-3 bg-gray-100 dark:bg-gray-800/50 rounded-lg text-left 
                      hover:bg-gray-200 dark:hover:bg-gray-800 transition-colors group"
                  >
                    <h3
                      className="text-sm font-medium text-gray-700 dark:text-gray-200 
                      flex items-center justify-between"
                    >
                      {action.title}
                      <ChevronRight className="w-4 h-4 text-gray-400 dark:text-gray-500" />
                    </h3>
                    <p className="text-sm text-gray-600 dark:text-gray-400 mt-1">{action.description}</p>
                  </button>
                ))}
              </div>

              <InputArea
                isStandalone={true}
                message={message}
                setMessage={setMessage}
                handleSendMessage={handleSendMessage}
                isLoading={isLoading}
                handleKeyDown={handleKeyDown}
              />
            </div>
          ) : (
            <div className="max-w-3xl mx-auto space-y-4 mb-4">
              {error && (
                <div className="mb-4 p-4 bg-red-100 dark:bg-red-900/50 text-red-700 dark:text-red-200 rounded-lg">
                  {error}
                </div>
              )}
              {chatMessages.map((msg, index) => (
                <ChatMessage key={index} {...msg} />
              ))}
              {isLoading && (
                <div className="flex items-center space-x-2">
                  <div className="w-8 h-8 rounded-full bg-gray-100 dark:bg-gray-800 flex items-center justify-center">
                    <Bot className="w-5 h-5 text-gray-500 dark:text-gray-400" />
                  </div>
                  <TypingIndicator />
                </div>
              )}
            </div>
          )}
           <div ref={messagesEndRef} /> 
        </div>

        {chatMessages.length > 0 && 
          <InputArea
            message={message}
            setMessage={setMessage}
            handleSendMessage={handleSendMessage}
            isLoading={isLoading}
            handleKeyDown={handleKeyDown}
          />
        }
      </div>
    </div>
  );
};

export default Dashboard;