import React, { Fragment, useState, useEffect, useCallback, memo, forwardRef } from "react";
import { LogOut, User, Settings, Key, UserCircle, HomeIcon, Bell } from 'lucide-react';
import { useNavigate, Link, useLocation } from "react-router-dom";
import { Menu, Transition, Dialog } from '@headlessui/react';
import { logos } from "../../assets";
import { ThemeSwitcher } from "../ThemeSwitcher";
import { useAuth } from "../../context/useAuth";
import axiosInstance from "../../utils/AxiosInstance";
import { BASE_URL } from "../../utils/Config";

const classNames = (...classes) => classes.filter(Boolean).join(" ");

const pageInfo = {
  '/main/dashboard': {
    title: 'Call Insights Hub',
    description: 'Your AI-powered call analysis command center.'
  },
  '/main/admin': {
    title: 'Admin',
    description: 'Manage your locations and settings.'
  },
  '/main/settings/profile': {
    title: 'Profile',
    description: 'Manage your personal information and preferences.'
  },
  '/main/settings/account': {
    title: 'Account',
    description: 'Manage your account settings and security.'
  }
};

const MemoizedNavigationLinks = memo(({ navigation, location }) => (
  <div className="space-y-4">
    {navigation.map((group) => (
      <div key={group.name}>
        <div className="px-3">
          <h3 className="text-xs font-semibold text-gray-900 uppercase tracking-wider dark:text-gray-400">
            {group.name}
          </h3>
          <div className="mt-2 space-y-1">
            {group.items.map((item) => {
              if (item.show === false) return null;
              return (
                <Link
                  key={item.name}
                  to={item.href}
                  className={classNames(
                    location.pathname === item.href
                      ? 'bg-gray-200 text-gray-900 dark:bg-gray-800 dark:text-white'
                      : 'text-gray-700 hover:bg-gray-200 hover:text-gray-900 dark:text-gray-300 dark:hover:bg-gray-700 dark:hover:text-white',
                    'group flex items-center px-3 py-2 text-sm font-medium rounded-md'
                  )}
                >
                  <item.icon className="mr-3 h-5 w-5 text-gray-500 dark:text-gray-400" aria-hidden="true" />
                  {item.name}
                </Link>
              );
            })}
          </div>
        </div>
      </div>
    ))}
  </div>
));

const UserMenu = forwardRef(({ onLogout }, ref) => (
  <Menu.Items
    ref={ref}
    className="absolute right-0 mt-2 w-48 rounded-lg bg-white dark:bg-gray-900 text-black dark:text-white shadow-lg border border-gray-300 dark:border-gray-700 z-50 bg-opacity-100 backdrop-filter-none"
  >
    <div className="py-1">
      <Menu.Item>
        {({ active }) => (
          <Link
            to="/main/settings/profile"
            className={`${active ? 'bg-gray-300 dark:bg-gray-700' : ''} flex items-center w-full px-4 py-2 text-sm text-gray-900 dark:text-gray-300`}
          >
            <User className="mr-2 h-5 w-5" />
            <span>Profile</span>
          </Link>
        )}
      </Menu.Item>
      <Menu.Item>
        {({ active }) => (
          <Link
            to="/main/settings/account"
            className={`${active ? 'bg-gray-300 dark:bg-gray-700' : ''} flex items-center w-full px-4 py-2 text-sm text-gray-900 dark:text-gray-300`}
          >
            <Key className="mr-2 h-5 w-5" />
            <span>Account</span>
          </Link>
        )}
      </Menu.Item>
      <Menu.Item>
        {({ active }) => (
          <button
            onClick={onLogout}
            className={`${active ? 'bg-gray-300 dark:bg-gray-700' : ''} flex items-center w-full px-4 py-2 text-sm text-gray-900 dark:text-gray-300`}
          >
            <LogOut className="mr-2 h-5 w-5" />
            <span>Sign out</span>
          </button>
        )}
      </Menu.Item>
    </div>
  </Menu.Items>
));


export const Layout = ({ children }) => {
  const auth = useAuth();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  const handleLogout = useCallback(() => {
    localStorage.removeItem("accessToken");
    navigate("/signin");
  }, [navigate]);

  const navigation = [
    {
      name: 'Main',
      items: [
        { name: 'Call Insights', href: '/main/dashboard', icon: HomeIcon },
        {
          name: 'Admin',
          href: '/main/admin',
          icon: UserCircle,
          show: auth.account?.role === 'admin'
        }
      ]
    },
    {
      name: 'Settings',
      items: [
        { name: 'Profile', href: '/main/settings/profile', icon: User },
        { name: 'Account', href: '/main/settings/account', icon: Key }
      ]
    }
  ];

  useEffect(() => {
    const id = localStorage.getItem("id");
    const token = localStorage.getItem("accessToken");

    if (!id || !token) {
      setIsLoading(false);
      return;
    }

    const controller = new AbortController();

    (async () => {
      try {
        const response = await axiosInstance.get(
          `${BASE_URL}/users/userId/${id}`,
          {
            headers: { Authorization: `Token ${token}` },
            signal: controller.signal
          }
        );
        auth.saveUser(response.data);
      } catch (error) {
        if (error.name !== 'CanceledError') {
          console.error("Error fetching user:", error);
        }
      } finally {
        setIsLoading(false);
      }
    })();

    return () => controller.abort();
  }, [auth]);

  if (isLoading) return null;

  return (
    <div className="flex h-screen overflow-hidden bg-white dark:bg-gray-900">
      {/* Mobile menu */}
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-40 lg:hidden"
          onClose={setSidebarOpen}
          static
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 flex z-40">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative flex-1 flex flex-col w-full max-w-xs bg-white dark:bg-gray-900">
                <div className="h-full flex flex-col">
                  <div className="px-4 py-3 border-b border-gray-300 flex items-center justify-between dark:border-gray-800">
                    <div className="h-8 w-auto dark:bg-transparent flex items-center justify-center">
                      <img
                        src={logos.Logo}
                        alt="Logo"
                        className="h-8 w-auto cursor-pointer dark:filter-none filter invert"
                        onClick={() => navigate("/main/dashboard")}
                      />
                    </div>
                    <button
                      onClick={() => setSidebarOpen(false)}
                      className="p-2 rounded-md text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
                    >
                      <span className="sr-only">Close sidebar</span>
                      <Settings className="h-6 w-6" />
                    </button>
                  </div>

                  <div className="flex-1 py-4">
                    <MemoizedNavigationLinks navigation={navigation} location={location} />
                  </div>

                  <div className="border-t border-gray-300 p-4 dark:border-gray-800">
                    <Menu as="div" className="relative">
                      <Menu.Button className="flex items-center w-full text-left rounded-lg hover:bg-gray-200 p-2 dark:hover:bg-gray-800">
                        <div className="flex items-center flex-1">
                          <div className="h-8 w-8 rounded-full bg-gray-200 flex items-center justify-center dark:bg-gray-700">
                            {auth.account?.firstname ? (
                              <span className="text-sm font-medium text-gray-900 dark:text-gray-300">
                                {auth.account.firstname[0]}
                              </span>
                            ) : (
                              <User className="h-5 w-5 text-gray-500 dark:text-gray-400" />
                            )}
                          </div>
                          <span className="ml-3 text-sm text-gray-900 dark:text-gray-300">
                            {auth.account?.firstname} {auth.account?.lastname}
                          </span>
                        </div>
                        <ThemeSwitcher />
                      </Menu.Button>

                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100 transform"
                        enterFrom="scale-95"
                        enterTo="scale-100"
                        leave="transition ease-in duration-75 transform"
                        leaveFrom="scale-100"
                        leaveTo="scale-95"
                      >

                        <Menu.Items className="absolute bottom-full left-0 mb-2 w-full rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:bg-gray-800">
                          <div className="py-1">
                            <UserMenu auth={auth} onLogout={handleLogout} />
                          </div>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Desktop sidebar */}
      <div className="hidden lg:flex lg:w-[280px] lg:flex-col lg:fixed lg:inset-y-0">
        <div className="flex flex-col flex-grow bg-white overflow-y-auto border-r border-gray-300 dark:bg-gray-900 dark:border-gray-800">
          <div className="px-4 py-3 border-b border-gray-300 dark:border-gray-800">
            <div className="h-8 w-auto dark:bg-transparent flex items-center justify-center">
              <img
                src={logos.Logo}
                alt="Logo"
                className="h-8 w-auto cursor-pointer dark:filter-none filter invert"
                onClick={() => navigate("/main/dashboard")}
              />
            </div>
          </div>

          <div className="flex-1 py-4">
            <MemoizedNavigationLinks navigation={navigation} location={location} />
          </div>
        </div>
      </div>

      {/* Main content */}
      <div className="lg:pl-[280px] flex flex-1 flex-col bg-white dark:bg-gray-900">
        <div className="lg:hidden flex items-center justify-between px-4 py-2 border-b border-gray-300 dark:border-gray-800 bg-white dark:bg-gray-900">
          <button
            type="button"
            className="p-2 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
            onClick={() => setSidebarOpen(true)}
          >
            <Settings className="h-6 w-6" />
          </button>
          <img src={logos.Logo} alt="Logo" className="h-8 w-auto" />
          <div className="w-10" />
        </div>

        <main className="flex-1 overflow-y-auto">
          <div className="flex items-center justify-between px-6 py-4 border-b border-gray-300 dark:border-gray-800 bg-white dark:bg-gray-900">
            <div>
              <h1 className="text-xl font-semibold text-gray-900 dark:text-gray-100">
                {pageInfo[location.pathname]?.title}
              </h1>
              <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
                {pageInfo[location.pathname]?.description}
              </p>
            </div>

            <div className="flex items-center space-x-4">
              <ThemeSwitcher />

              <button className="p-2 rounded-full hover:bg-gray-200 dark:hover:bg-gray-700">
                <Bell className="h-6 w-6 text-gray-600 dark:text-gray-300" />
              </button>

              <Menu as="div" className="relative">
                <Menu.Button className="flex items-center space-x-3 rounded-lg hover:bg-gray-200 p-2 dark:hover:bg-gray-800">
                  <div className="h-8 w-8 rounded-full bg-gray-200 flex items-center justify-center dark:bg-gray-700">
                    {auth.account?.firstname ? (
                      <span className="text-sm font-medium text-gray-900 dark:text-gray-300">
                        {auth.account.firstname[0]}
                      </span>
                    ) : (
                      <User className="h-5 w-5 text-gray-500 dark:text-gray-400" />
                    )}
                  </div>
                  <span className="hidden md:block text-sm text-gray-900 dark:text-gray-300">
                    {auth.account?.firstname} {auth.account?.lastname}
                  </span>
                </Menu.Button>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100 transform"
                  enterFrom="scale-95"
                  enterTo="scale-100"
                  leave="transition ease-in duration-75 transform"
                  leaveFrom="scale-100"
                  leaveTo="scale-95"
                >

                  <UserMenu auth={auth} onLogout={handleLogout} />
                </Transition>
              </Menu>
            </div>
          </div>

          <div className="bg-white dark:bg-gray-900 mt-6 px-6">
            {children}
          </div>

        </main>
      </div>
    </div>
  );
};

export default Layout;